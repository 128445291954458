import annot from '../../../../data/annotations.json';
import tgpStore from '../tgpStore';
import Patient from './patient';
import {User} from './user';

class Lab {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public affiliation: string,
    public country: string
  ) {}

  get members(): User[] {
    return tgpStore.users.filter(u => u.labMembers.some(l => l.lab == this));
  }

  get patients(): Patient[] {
    return tgpStore.patients.filter(p => p.lab == this);
  }

}

class LabMember {
  role: number;
  memberDate: Date;
  lab: Lab;
  constructor(role: number, memberDate: Date, lab: Lab) {
    this.role = role;
    this.memberDate = memberDate;
    this.lab = lab;
  }

  get isPi(): boolean {
    return this.role == 0;
  }

  get isAdmin(): boolean {
    return this.role == 1;
  }

  get hasAdminAccess(): boolean {
    return this.role <= 1;
  }

  get labRoleGrantOptions() {
    return annot.labUserRole.enum.filter(l => l.id >= this.role);
  }
}

class Project {
  id: string;
  name: string;
  description: string;
  constructor(id, name, description) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  get patients(): Patient[] {
    return tgpStore.patients.filter(p => p.projects.includes(this));
  }

  get users(): User[] {
    return tgpStore.users.filter(u =>
      u.projectMembers.some(p => p.project == this)
    );
  }

  get admin(): User {
    return this.users.find(u =>
      u.projectMembers.some(pm => pm.project == this && pm.isAdmin)
    );
  }

  getUserRole(user: User): number {
    return tgpStore.users
      .find(u => u == user)
      .projectMembers.find(pm => pm.project == this).role;
  }

  get members(): User[] {
    return tgpStore.users.filter(u =>
      u.projectMembers.some(l => l.project == this)
    );
  }
}

class ProjectMember {
  role: number;
  memberDate: Date;
  project: Project;
  constructor(role: number, memberDate: Date, project: Project) {
    this.role = role;
    this.memberDate = memberDate;
    this.project = project;
  }

  get isAdmin(): boolean {
    return this.role == 0;
  }
}

export {Lab, LabMember, Project, ProjectMember};
