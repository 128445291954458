import model from './model';

class SolvedStatus {
  timestamp: Date;
  familyId: any;
  family: any;
  solvedStatus: any;
  solvedStatusText: any;
  isSolved: boolean;
  gene: any;
  note: any;
  userId: any;
  firstName: any;
  lastName: any;
  hasMemberAccess: boolean;
  constructor(data) {
    this.timestamp = new Date(data.timestamp);
    this.familyId = data.familyId;
    this.family = data.family ? data.family : null;
    this.solvedStatus = data.solvedStatus;
    this.solvedStatusText = model.getEnumName(
      'solvedStatus', this.solvedStatus);
    this.isSolved = this.solvedStatus > 0;
    this.gene = data.gene ? data.gene.trim() : undefined;
    this.note = data.note;
    this.userId = data.userId;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.hasMemberAccess = Boolean(this.userId)
  }
}

class VariantStatus extends SolvedStatus {
  variantId: any;
  chrom: any;
  pos: any;
  ref: any;
  alt: any;
  acmg: any;
  acmgText: any;
  constructor(data) {
    super(data);
    this.variantId = data.variantId;
    this.chrom = data.chrom;
    this.pos = data.pos;
    this.ref = data.ref;
    this.alt = data.alt;
    this.acmg = data.acmg;
    this.acmgText = model.getEnumName('variant_status', this.acmg);
  }
}

class VariantStatusSet {
  variantStatuses: any[];
  constructor() {
    this.variantStatuses = [];
  }

  get variantStatusCount() {
    return this.variantStatuses.length;
  }

  // Last status should be the most recent (i.e. active) one
  get activeVariantStatus() {
    return this.variantStatuses[this.variantStatusCount - 1];
  }

  // Sort to ensure most recent status is last
  addVariantStatus(variantStatus) {
    this.variantStatuses.push(variantStatus);
    this.variantStatuses.sort((a, b) => a.timestamp - b.timestamp);
  }
}

export default {
  SolvedStatus,
  VariantStatus,
  VariantStatusSet
};
