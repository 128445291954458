function formatHeaderCellTemplate(colName) {
  colName = colName.replace('!', '<br/>');
  return "<div ng-class=\"{ 'sortable': sortable }\"><div class=\"ui-grid-vertical-bar\">&nbsp;</div><div class=\"ui-grid-cell-contents\" col-index=\"renderIndex\"><span><div style=\"text-align:center;\">"+colName+"</div></span> <span ui-grid-visible=\"col.sort.direction\" ng-class=\"{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }\" >&nbsp;</span></div><div class=\"ui-grid-column-menu-button\" ng-if=\"grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false\" class=\"ui-grid-column-menu-button\" ng-click=\"toggleMenu($event)\"><i class=\"ui-grid-icon-angle-down\">&nbsp;</i></div><div ng-if=\"filterable\" class=\"ui-grid-filter-container\" ng-repeat=\"colFilter in col.filters\"><input type=\"text\" class=\"ui-grid-filter-input\" ng-model=\"colFilter.term\" ng-click=\"$event.stopPropagation()\" ng-attr-placeholder=\"{{colFilter.placeholder || ''}}\"><div class=\"ui-grid-filter-button\" ng-click=\"colFilter.term = null\"><i class=\"ui-grid-icon-cancel right\" ng-show=\"!!colFilter.term\">&nbsp;</i></div></div></div>"
}

// Sort most pathogenic flags first on initial click
function sortVariantFlags(a, b) {
  return !a || !('acmg' in a) || b && 'acmg' in b && b.acmg < a.acmg ?
    1 : -1;
}

const cols = [
  { 
    field: 'patient.family.shareName', 
    name: 'Family Number',
    headerCellTemplate: formatHeaderCellTemplate('Family!Number'),
    cellTemplate: "views/vendor/pages/sampleList/familyCellTemplate.html",
    enableSorting: true
  },
  { 
    field: 'patient.shareName', 
    name: 'Participant Number',
    headerCellTemplate: formatHeaderCellTemplate('Participant!Number'),
    enableSorting: true
  },
  { 
    field: 'shareName', 
    name: 'Sample Number',
    headerCellTemplate: formatHeaderCellTemplate('Sample!Number'),
    enableSorting: true,
    visible: false
  },
  // { 
  //   field: 'matchId', 
  //   name: 'Sample Match ID',
  //   headerCellTemplate: formatHeaderCellTemplate('Sample!Match ID'),
  //   enableSorting: true,
  //   visible: false
  // },
  // { 
  //   field: 'patient.matchId', 
  //   name: 'Patient Match ID',
  //   headerCellTemplate: formatHeaderCellTemplate('Patient!Match ID'),
  //   enableSorting: true,
  //   visible: false
  // },
  // { 
  //   field: 'patient.family.matchId', 
  //   name: 'Family Match ID',
  //   headerCellTemplate: formatHeaderCellTemplate('Family!Match ID'),
  //   enableSorting: true,
  //   visible: false
  // },
  { 
    field: 'genesisNumber', 
    name: 'GENESIS Number',
    displayName: 'GENESIS Number',
    headerCellTemplate: formatHeaderCellTemplate('GENESIS!Number'),
    enableSorting: true,
    visible: false
  },
  {
    field: 'createDate', 
    name: 'Upload Date',
    headerCellTemplate: formatHeaderCellTemplate('Upload!Date'), 
    cellFilter: "date:'yyyy-MM-dd'",
    enableSorting: true, visible: false
  },
  {
    field: 'patient.primaryDiagnosis.name', 
    name: 'Primary Diagnosis',
    headerCellTemplate: formatHeaderCellTemplate('Primary!Diagnosis'), 
    enableSorting: true
  },
  {
    field: 'patient.modeOfInheritance', 
    name: 'Inheritance Pattern',
    cellFilter: 'modeOfInheritance',
    headerCellTemplate: formatHeaderCellTemplate('Inheritance!Pattern'),
    enableSorting: true,
  },
  { 
    field: 'patient.affection', 
    name: 'Affection Status',
    headerCellTemplate : formatHeaderCellTemplate('Affection!Status'),
    cellFilter: 'affectionStatus',
    enableSorting: true 
  },
  {
    field: 'experimentTypeText', 
    name: 'Experiment Type',
    headerCellTemplate: formatHeaderCellTemplate('Experiment!Type'),
    enableSorting: true,
  },
  { 
    field: 'patient.lab.name', 
    name: 'Lab Name',
    headerCellTemplate: formatHeaderCellTemplate('Lab!Name'),
    cellTemplate: 'views/vendor/pages/sampleList/labName.html',
    enableSorting: true
  },
  {
    field: 'patient.family.solvedGenesText', 
    name: 'Solved genes',
    headerCellTemplate: formatHeaderCellTemplate('Solved!Genes'),
    enableSorting: true,
  },
  { 
    field: 'patient.sex', 
    name: 'Participant Gender',
    headerCellTemplate: formatHeaderCellTemplate('Participant!Gender'),
    cellFilter: 'sex',
    enableSorting: true, visible: false
  },
  { 
    field: 'patient.ageOfOnset', 
    name: 'Age of Onset',
    headerCellTemplate: formatHeaderCellTemplate('Age of!Onset'),
    enableSorting: true, visible: false
  },
  { 
    field: 'patient.consanguinity', 
    name: 'Participant Consanguinity',
    headerCellTemplate: formatHeaderCellTemplate('Participant!Consanguinity'),
    cellFilter: 'consanguinity',
    enableSorting: true, visible: false
  },
  { 
    field: 'patient.superPopulation', 
    name: 'Super Population',
    headerCellTemplate: formatHeaderCellTemplate('Super!Population'),
    cellFilter: 'superPopulation',
    enableSorting: true, visible: false
  },
  { 
    field: 'hasSnv', 
    displayName: 'Has SNV',
    name: 'Has SNV',
    headerCellTemplate: formatHeaderCellTemplate('Has!SNV'),
    cellTemplate: 'views/vendor/pages/sampleList/hasSnv.html',
    enableSorting: true, visible: false
  },
  { 
    field: 'hasSv', 
    displayName: 'Has SV',
    name: 'Has SV',
    headerCellTemplate: formatHeaderCellTemplate('Has!SV'),
    cellTemplate: 'views/vendor/pages/sampleList/hasSv.html',
    enableSorting: true, visible: false
  },
  { 
    field: 'hasCnn', 
    displayName: 'Has CNN',
    name: 'Has CNN',
    headerCellTemplate: formatHeaderCellTemplate('Has!CNN'),
    cellTemplate: 'views/vendor/pages/sampleList/hasCnn.html',
    enableSorting: true, visible: false
  },
  { 
    field: 'hasRoh', 
    displayName: 'Has ROH',
    name: 'Has ROH',
    headerCellTemplate: formatHeaderCellTemplate('Has!RoH'),
    cellTemplate: 'views/vendor/pages/sampleList/hasRoh.html',
    enableSorting: true, visible: false
  },
  { 
    field: 'patient.family.hasPedigree', 
    displayName: 'Has Pedigree',
    name: 'Has Pedigree',
    headerCellTemplate: formatHeaderCellTemplate('Has!Pedigree'),
    cellTemplate: 'views/vendor/pages/sampleList/hasPedigree.html',
    enableSorting: true, visible: false
  },
  { 
    field: 'isShared', 
    displayName: 'Is Shared',
    name: 'Is Shared',
    headerCellTemplate: formatHeaderCellTemplate('Is!Shared'),
    cellTemplate: 'views/vendor/pages/sampleList/isShared.html',
    enableSorting: true, visible: false
  },
  { 
    field: 'alignmentRate', 
    name: 'Alignment Rate',
    cellFilter: 'number:2',
    headerCellTemplate: formatHeaderCellTemplate('Alignment!Rate'),
    enableSorting: true, visible: false
  },
  { 
    field: 'avgDepth', 
    name: 'Average Depth',
    cellFilter: 'number:2',
    headerCellTemplate: formatHeaderCellTemplate('Average!Depth'),
    enableSorting: true, visible: false
  },
  { 
    field: 'avgGq', 
    displayName: 'Average GQ',
    name: 'Average GQ',
    cellFilter: 'number:2',
    headerCellTemplate: formatHeaderCellTemplate('Average!GQ'),
    enableSorting: true, visible: false
  },
  { 
    field: 'avgQual', 
    name: 'Average Qual',
    headerCellTemplate: formatHeaderCellTemplate('Average!Qual'),
    cellFilter: 'number:2',
    enableSorting: true, visible: false
  },
  { 
    field: 'zAlignmentRate', 
    name: 'Alignment Rate (Z-Score)',
    cellFilter: 'number: 2',
    headerCellTemplate: formatHeaderCellTemplate('Alignment Rate!(Z-Score)'),
    enableSorting: true, visible: false
  },
  { 
    field: 'zAvgDepth', 
    name: 'Average Depth (Z-Score)',
    cellFilter: 'number: 2',
    headerCellTemplate: formatHeaderCellTemplate('Avg. Depth!(Z-Score)'),
    enableSorting: true, visible: false
  },
  { 
    field: 'zAvgGq', 
    name: 'Average GQ (Z-Score)',
    headerCellTemplate: formatHeaderCellTemplate('Avg. GQ!(Z-Score)'),
    cellFilter: 'number: 2',
    displayName: 'Average GQ (Z-Score)',
    enableSorting: true, visible: false
  },
  { 
    field: 'zAvgQual', 
    name: 'Average Qual (Z-Score)',
    cellFilter: 'number: 2',
    headerCellTemplate: formatHeaderCellTemplate('Avg. Qual!(Z-Score)'),
    enableSorting: true, visible: false
  },
  { 
    field: 'patient.notes', 
    name: 'Patient Notes',
    headerCellTemplate: formatHeaderCellTemplate('Patient!Notes'),
    enableSorting: true, visible: false
  },
  {
    field: 'hasQc', 
    displayName: 'Has QC Module',
    name: 'Has QC Module',
    headerCellTemplate: formatHeaderCellTemplate('Has!QC Module'),
    cellTemplate: 'views/vendor/pages/sampleList/hasQc.html',
    enableSorting: true, visible: false
  },
  { 
    field: 'patient.family.maxVariantFlag', 
    displayName: 'Variant Pathogenicity',
    name: 'Variant Pathogenicity',
    headerCellTemplate: formatHeaderCellTemplate('Variant!Pathogenicity'),
    cellTemplate: 'views/vendor/pages/sampleList/maxVariantFlag.html',
    sortingAlgorithm: sortVariantFlags,
    enableSorting: true, visible: false
  },
  {
    field: 'isPrimarySample', 
    name: 'Primary Sample',
    cellTemplate: 'views/vendor/pages/sampleList/isPrimarySample.html',
    headerCellTemplate: formatHeaderCellTemplate('Default!Sample'),
    enableSorting: true, visible: false
  },
  {
    field: 'hasData', 
    name: 'Data Available',
    cellTemplate: 'views/vendor/pages/sampleList/dataAvailable.html',
    headerCellTemplate: formatHeaderCellTemplate('Data!Available'),
    enableSorting: true, visible: false
  },
  {
    field: 'patient.hasLoginUserAdminAccess', 
    name: 'Admin Access',
    cellTemplate: 'views/vendor/pages/sampleList/hasLoginUserAdminAccess.html',
    headerCellTemplate: formatHeaderCellTemplate('Admin!Access'),
    enableSorting: true, visible: false
  },
  {
    field: 'fileType', 
    name: 'File Type',
    headerCellTemplate: formatHeaderCellTemplate('File!Type'),
    enableSorting: true, visible: false
  }
];

export default cols;
