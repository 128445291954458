const diagnosisLookup = {}, hpoLookup = {}, primaryDiagnosisLookup = {};
const diagnosisGroups = [];
const popQc = [];  // Population-level medians, etc.
const familyVariantLookup = {}, familyForcedSolvedGenesLookup = {};
import knownDiseaseGenesData from '../../../data/knownDiseaseGenes.json';
const knownDiseaseGenes: any[] = knownDiseaseGenesData; 
import predefinedFilters from '../../../data/predefinedFilters.json';
import annotations from '../../../data/annotations.json';
//const queryFilters = require('../../../data/queryFilters.json');
const knownDiseaseGenesLookup = Object.fromEntries(
  knownDiseaseGenes.map(g => [g.id, g]));

function getEnum(type) {
  return annotations[type] && annotations[type].enum ?
    annotations[type].enum : null;
}

function getEnumName(type, id) {
  const enums = getEnum(type);
  if (!enums) return null;
  const e = enums.find(e => e.id == id);
  if (!e || !e.name) return null;
  return e.name;
}

function initPopulationQcStats(stats) {
  popQc.push(...stats);
}

export default {
  initPopulationQcStats: initPopulationQcStats,
  diagnosisGroups: diagnosisGroups,
  diagnosisLookup: diagnosisLookup,
  familyVariantLookup: familyVariantLookup,
  familyForcedSolvedGenesLookup: familyForcedSolvedGenesLookup,
  getEnum: getEnum,
  getEnumName: getEnumName,
  hpoLookup: hpoLookup,
  knownDiseaseGenes: knownDiseaseGenes,
  knownDiseaseGenesLookup: knownDiseaseGenesLookup,
  popQc: popQc,
  predefinedFilters: predefinedFilters,
  primaryDiagnosisLookup: primaryDiagnosisLookup,
  sampleCrossfilter: null,
  sampleFilters: null,
  queryPresetStore: null,
  samplePresetStore: null,
};
