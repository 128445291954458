import model from '../model';
import tgpStore from '../tgpStore';
import Patient from './patient';

function uniq_gene_names(items) {
    return [...new Set(items.map(i => i.gene))].sort();
}

class Family {
  id: any;
  name: string;
  matchId: string;
  patients: Patient[];
  variantStatusSets: any;
  forceSolvedGenes: any[];
  family: any;
  hasPedigree: boolean;
  hasMemberAccess: boolean;
  constructor(id, name, matchId, patients, variantStatusSets, hasPedigree: boolean) {
    this.id = id;
    this.name = name;
    this.matchId = matchId;
    this.patients = patients;
    this.variantStatusSets = variantStatusSets;
    this.forceSolvedGenes = [];
    this.hasPedigree = hasPedigree
    this.hasMemberAccess = Boolean(name)
  }

  get activeVariantFlags() {
    return !this.anyVariantStatuses ? null :
    // @ts-ignore
      Object.values(this.variantStatusSets).map(v => v.activeVariantStatus);
  }

  get anyVariantStatuses() {
    return Object.keys(this.variantStatusSets).length ? true : false;
  }

  get anyForcedSolved() {
    return this.forceSolvedGenes.length ? true : false;
  }

  get forceSolvedGeneNames() {
    if (!this.anyForcedSolved) return null;
    return uniq_gene_names(this.forceSolvedGenes);
  }

  get isSolved() {
    return this.solvedVariants || this.anyForcedSolved ? true : false;
  }
  // DEPRECATED: use isSolved instead
  get isFamilySolved() { return this.isSolved; }

  get maxVariantFlag() {
    if (!this.activeVariantFlags) return null;
    return this.activeVariantFlags.reduce((maxFlag, flag) =>
      !('acmg' in maxFlag) || 'acmg' in flag && flag.acmg < maxFlag.acmg ?
      flag : maxFlag
    );
  }

  get shareName() : string {
    return this.name || this.matchId;
  }

  get solvedStatus() {
    if (this.anyForcedSolved)
      return 2;
    else if (this.solvedVariants)
      return Math.max(...this.solvedVariants.map(s => s.solvedStatus));
    else
      return 0;
  }

  get solvedStatusText() { 
    return model.getEnumName('solvedStatus', this.solvedStatus);
  }

  get solvedVariants() {
    if (!this.activeVariantFlags) return null
    const solvedVariants = this.activeVariantFlags.filter(v => v.isSolved);
    return solvedVariants.length > 0 ? solvedVariants : null;
  }

  get solvedGeneNames() {
    const solved = [];
    if (this.solvedVariants) solved.push(...this.solvedVariants);
    solved.push(...this.forceSolvedGenes);  // OK if empty
    return uniq_gene_names(solved);
  }

  get solvedGenesText() {
    if (!this.solvedVariants && !this.anyForcedSolved) return null;
    return this.solvedGeneNames.join(',');
  }

  get variantSolvedGeneNames() {
    if (!this.solvedVariants) return null;
    return uniq_gene_names(this.solvedVariants);
  }

  addForceSolvedGene(solvedGene) {
    this.forceSolvedGenes.push(solvedGene);
  }

  addVariantStatus(variantStatus) {
    const vid = variantStatus.variantId;
    tgpStore.addVariantStatus(variantStatus);
    if (!this.findVariantStatusSet(vid)) {
      this.variantStatusSets[vid] =
        tgpStore.familyVariantStatusLookup[this.id][vid];
    }
    if (variantStatus.isSolved && this.anyForcedSolved) {
      const sg = this.family.forceSolvedGenes.find(
        s => s.gene == variantStatus.gene);
      if (sg) this.family.removeSolvedGene(sg);
    }
  }

  findVariantStatusSet(variantId) {
    return variantId in this.variantStatusSets ?
      this.variantStatusSets[variantId]: null;
  }

  removeForceSolvedGene(solvedGene) {
    const idx = this.forceSolvedGenes.findIndex(f => f == solvedGene);
    this.forceSolvedGenes.splice(idx, 1);
  }

}

export default Family;
