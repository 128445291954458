class PresetStore {
  presets: any[];
  initComplete: boolean;
  constructor() {
    this.presets = [];
    this.initComplete = false;
  }

  get isEmpty() { return this.presets.length == 0; }

  add(preset) {
    this.presets.push(preset);
  }

  findByName(searchName) {
    return this.presets.find(p => p.name == searchName);
  }

  update(updatedPreset) {
    const existingPreset = this.findByName(updatedPreset.name);
    existingPreset.settings = updatedPreset.settings;
  }

  init(presets) {
    presets.forEach(p => this.add(p));
    this.initComplete = true;
  }

  remove(preset) {
    const idx = this.presets.findIndex(p => p == preset);
    this.presets.splice(idx, 1);
  }
}

export default PresetStore;
