import Papa from "papaparse";
import QueryResults from './queryResults';
import FamilySnv from './familyVariant/familySnv';
import FamilySv from './familyVariant/familySv';

const parseConfig = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  delimiter: "\t"
}

function deserialize(queryId, response) {
  let [params, results] = response.split('|-|');
  params = JSON.parse(params);
  const parsed = Papa.parse(results, parseConfig);
  const famVars = parsed.data.map(d => {
    return d.sv_id || d.svid ? new FamilySv(d, params) : new FamilySnv(d, params);
  });
  return new QueryResults(queryId, famVars, params);
}

export default {
  deserialize: deserialize
};


