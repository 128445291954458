import geneAliases from './geneAliases';
import enums from '../../../data/enums.json';
const ontologyEnums = enums.vep_sequence_ontology;
const ontologyByCategory = ontologyEnums.reduce((acc, item) => {
  if (item.category in acc) acc[item.category].push(item);
  else acc[item.category] = [item];
  return acc;
}, {});

function ontologyCategorySelectAll(category) {
  if (this.value == null) this.value = [];
  const categoryIds = ontologyByCategory[category].map(o => o.id);
  for (let cid of categoryIds) {
    const idx = this.value.findIndex(v => v == cid);
    if (idx == -1) this.value.push(cid);
  }
}

function ontologyCategoryClear(category) {
  if (!this.isActive) return;
  const categoryIds = ontologyByCategory[category].map(o => o.id);
  for (let cid of categoryIds) {
    const idx = this.value.findIndex(v => v == cid);
    if (idx == -1) continue;
    this.value.splice(idx, 1);
  }
}

// Intended for use with numeric input fields
function evalValidValue() {
  function isNumber(x) {
    //Reference: https://stackoverflow.com/questions/175739/
    return !isNaN(x) && !isNaN(parseFloat(x));
  }
  const val = this.value;
  const INTEGER_REGEXP = /^\d+$/;
  const alwaysValidTypes = ['radio', 'select', 'template', 'text'];
  if (val == null || val == '') return true;  // empty is ok
  if (alwaysValidTypes.includes(this.type)) return true; 
  else if (this.type == 'integer')
    return INTEGER_REGEXP.test(val);
  else if (this.type == 'frequency') 
    return isFinite(val) && val >= 0 && val <= 1;
  else if (this.type == 'percentile') 
    return isFinite(val) && val >= 0 && val <= 100;
  else if (this.type == 'unsigned') 
    return isFinite(val) && val >= 0;
  else if (this.type == 'number') return isNumber(val);
}

function evalValidVepGene() {
  const val = this.value;
  if (val == null || val == '') return true;  // empty is ok
  return this.invalidGeneNames().length == 0;
}

function geneStringToUpperArray() {
  const rxReplace = RegExp(/,+/g);
  const rxSplit = RegExp(/ +/g);
  return this.value.toUpperCase().replace(rxReplace, ' ')
    .trim().split(rxSplit);
}

function getGeneAliases() {
  const val = this.value;
  const aliasedGenes = [];
  if (val == null || val == '') return null;  // empty is ok
  for (let g of this.getGeneArray())
    if (geneAliases.getPrimaryGeneName(g) != g)
      aliasedGenes
        .push({alias: g, primaryName: geneAliases.getPrimaryGeneName(g)});
  return aliasedGenes.length ? aliasedGenes : null;
}

function getPrimaryGeneNames() {
  return this.getGeneArray().map(g => geneAliases.getPrimaryGeneName(g));
}

function invalidGeneNames() {
  const val = this.value;
  if (val == null || val == '') return null;  // empty is ok
  return this.getGeneArray().filter(g => !geneAliases.isValidGeneName(g));
}

// Select all for checkbox inputs
function selectAll() {
  this.value = this.options.map(o => o.id);
}

class Filter {
  block: any;
  value: any;
  id: string;
  options: { id: number; name: string; category: string; }[];
  ontologyByCategory: {};
  ontologyCategorySelectAll: (category: any) => void;
  ontologyCategoryClear: (category: any) => void;
  type: string;
  selectAll: () => void;
  invalidGeneNames: () => any;
  getGeneAliases: () => any[];
  getGeneArray: () => any;
  getPrimaryGeneNames: () => any;
  _evalValidValue: () => boolean;
  optionLookup: any;
  constructor(block, rawFilter) {
    Object.assign(this, rawFilter)
    this.block = block;
    this.value = null;
    
    // Input-specific functionality
    if (this.id == 'vep_ontology') {
      this.options = ontologyEnums;
      this.ontologyByCategory = ontologyByCategory;
      this.ontologyCategorySelectAll = ontologyCategorySelectAll;
      this.ontologyCategoryClear = ontologyCategoryClear;
    } else if (this.type == 'select') {
      this.selectAll = selectAll;
    }
    else if (this.id == 'vep_gene') {
      this.invalidGeneNames = invalidGeneNames;
      this.getGeneAliases = getGeneAliases;
      this.getGeneArray = geneStringToUpperArray;
      this.getPrimaryGeneNames = getPrimaryGeneNames;
    }

    this._evalValidValue = this.id == 'vep_gene' ? evalValidVepGene :
      evalValidValue;
    
    if ('options' in this) {
      const optionEntries = this.options.map(o => [o.id, o.name]);
      this.optionLookup = Object.fromEntries(optionEntries);
    }

  }

  get isNumber() {
    return ['integer', 'frequency', 'number'].includes(this.type);
  }

  // Note that HTML input elements trim whitespace from value, so do not need
  // to test for that.
  // e.g. ' a a ' -> 'a a',  ' ' -> ''
  get isActive() {
    const val = this.value;
    if (val == null) return false;
    const vtype = Object.prototype.toString.call(val);
    if (vtype == '[object Object]')
      // Expect object keys to have string values; not arrays or objects
      return Object.values(val).some(v => v);
    else if (vtype == '[object Array]') return val.length > 0;
    else return val === 0 || val ? true : false;
  }

  get hasValidValue() {
    return this._evalValidValue();
  }

  get prettyValue() {
    if (this.id == 'vep_gene') return this.getPrimaryGeneNames().join(', ');
    // @ts-ignore
    if (!('options' in this)) return this.value;
    if (this.type == 'radio') return this.optionLookup[this.value];
    return this.value.map(v => this.optionLookup[v]).join(', ');
  }

  get valueType() {
    const val = this.value;
    if (val == null) return null;
    const vtype = Object.prototype.toString.call(val);
    if (vtype == '[object Object]') return 'object';
    else if (vtype == '[object Array]') return 'array';
    else return 'literal';
  }

  clear() { this.value = null; };

  matchesKeyword(keyword) {
    const regex = new RegExp(keyword, 'i');
    // @ts-ignore
    return regex.test(this.label) ||
      regex.test(this.block.name) ||
      regex.test(this.block.description) ||
      'options' in this && this.options.some(o => regex.test(o.name));
  }

}

export default Filter;
