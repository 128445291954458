import Filter from './filter';
import model from './model';
import rawBlocksData from '../../../data/queryFilters.json';
const rawBlocks: any[] = rawBlocksData; 

class QueryFilters {
  blocks: Block[];
  snvVariantClassFilter: any;
  svVariantClassFilter: any;
  constructor() {
    this.blocks = rawBlocks.map(b =>
      new Block(b.id, b.name, b.description, b.notes, b.elements));
    this.snvVariantClassFilter = this.findElementById('sv_class');
    this.svVariantClassFilter = this.findElementById('vep_ontology');
    // Dynamically set checkbox options based on available data in
    // knownDiseaseGenes
    const knownGeneElement = this.findElementById('known_genes');
    const knownGeneOptions = model.knownDiseaseGenes.map(g => { return {name: g.label, id: g.id} });
    knownGeneElement.options = knownGeneOptions;
  }

  get activeFilters() {
    const result = [];
    for (let blk of this.blocks)
      for (let flt of blk.activeFilters)
        result.push(flt);
    return result;
  }

  get anyActiveBlocks() {
    return this.blocks.some(b => b.hasActiveFilters);
  }

  get anyInvalidFilters() {
    return this.blocks.some(b => b.anyInvalidFilters);
  }

  get anyVariantClassFilterActive() {
    return this.snvVariantClassFilter.isActive ||
      this.svVariantClassFilter.isActive;
  }

  get formattedSettings() {
    return this.serializeFilterSettings(true);
  }

  applyFilterSettings(settings) {
    for (let blk of this.blocks) {
      for (let flt of blk.filters) {
        // Set value if found. Clear value otherwise
        if (Object.keys(settings).includes(flt.id)) {
          const setVal = settings[flt.id];
          const setType = Object.prototype.toString.call(setVal);
          if (flt.id == 'vep_gene') flt.value = setVal.join(', ');
          // Shallow copy via spread to avoid assigning reference
          else if (setType == '[object Object]') flt.value = {...setVal};
          else if (setType == '[object Array]') flt.value = [...setVal];
          // Convert to string for compatability with HTML input elements
          else flt.value = String(setVal);
        }
        else flt.clear();
      }
    }
  }

  clear() {
    for (let b of this.blocks) b.clear();
  }

  findElementById(filterId) {
    for (let blk of this.blocks) {
      for (let flt of blk.filters) {
        if (flt.id == filterId) return flt;
      }
    }
    return undefined;
  }

  // Alias for findElementById
  findFilterById(filterId) { return this.findElementById(filterId); }
  
  serializeFilterSettingsTableFormat() {
    return this.activeFilters
      .map(f => [f.block.name, f.label, String(f.prettyValue)]);
  }

  serializeFilterSettings(pretty = false) {
    const result = {};
    for (let blk of this.blocks) {
      if (!blk.hasActiveFilters) continue;
      for (let flt of blk.activeFilters) {
        const key = pretty ? flt.label : flt.id;
        let val;
        if (flt.id == 'vep_gene') val = flt.getPrimaryGeneNames();
        else val = pretty ? flt.prettyValue : flt.value;
        result[key] = flt.isNumber ? Number(val) : val;
      }
    }
    return result;
  }
}

class Block {
  id: any;
  name: any;
  description: any;
  notes: any;
  filters: any;
  constructor(id, name, description, notes, rawFilters) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.notes = notes;
    this.filters = rawFilters.map(f => new Filter(this, f));
  }

  get activeFilters() {
    return this.filters.filter(f => f.isActive);
  }


  get hasActiveFilters() {
    if (this.name == 'Gene & Allele Enrichment')
      return this.activeFilters.some(f => f.id == 'enrichment_type') &&
        this.activeFilters.some(f =>
          f.hasValidValue &&
          (f.id == 'enrichment_min' || f.id == 'enrichment_max')
        );
    return this.filters.some(f => f.isActive);
  }

  get anyInvalidFilters() {
    return this.invalidFilters.length ? true : false;
  }

  get invalidFilters() {
    return this.filters.filter(f => !f.hasValidValue);
  }

  clear() {
    for (let f of this.filters) f.clear();
  }
}


const queryFilters = new QueryFilters();

export { QueryFilters, queryFilters };

