/* NOTES: It is tempting to try to factor out the hasOwnProperty check into the
launcher but then every filter would need a perfect mapping to patient
attributes */

const filterFunctions /* filterMap filterLookup*/ = {
  affection: patientHasAnyAffection,
  experimentType: patientHasAnyExperimentTypes,
  hpo: patientHasAnyHpos,
  projects: patientHasAnyProjects
};


function patientHasAnyHpos(patient, hpoIds) {
  return patient.hasOwnProperty("hpo")
    && patient.hpo.some(h => hpoIds.includes(h.id));
}

function patientHasAnyProjects(patient, projectIds) {
  return patient.hasOwnProperty("projects")
    && patient.projects.some(p => projectIds.includes(p));
}

function patientHasAnyAffection(patient, affectionCodes) {
  return affectionCodes.includes(patient.affection);
}

function patientHasAnyExperimentTypes(patient, experimentTypes) {
  return patient.samples.some(s => experimentTypes.includes(s.experimentType));
}

// DEPRECATED
function patientHasHpo(patient, hpoId) {
  return patient.hasOwnProperty("hpo")
    && patient.hpo.some(h => h.id == hpoId);
}
// DEPRECATED
function patientHasItemTypeId(patient, itemType, id) {
  return patient.hasOwnProperty(itemType)
    && patient[itemType].some(i => i.id == id);
}

function addPatientToProject(patientId, projectId, patients) {
  const patient = patients.find(p => p.patientId == patientId);
  if (!patient.hasOwnProperty("projects")) patient.projects = [projectId];
  else patient.projects.push(projectId);
}

function removePatientFromProject(patientId, projectId, patients) {
  const patientProjects = patients.find(p => p.patientId == patientId).projects;
  const projectIdx = patientProjects.findIndex(pid => pid == projectId);
  patientProjects.splice(projectIdx, 1);
}

function gridView(patients) {
  return patients.map(p => {
    return {
      labName: p.labName,
      familyExternalId: p.familyExternalId,
      individualId: p.individualId,
      sex: p.sex,
      affectionStatus: p.affectionStatus,
      solvedGene: p.solvedGene,
      availableExperiments: listExperiments(p),
      modeOfInheritance: {"name": p.modeOfInheritance},
    };
  })
}


function searchTerms(patients) {
  let objectToArray = (obj) => Object.keys(obj).map(k => obj[k]);
  // Collect HPO, diagnoses and families in objects to ensure uniqueness.  
  // Patients and samples are unique owing to the data model, and 
  // can be collected directly into an array
  let hpo = {}, diagnosis = {}, fam = {},
    indiv = [], samp = [], inherit = new Set();
  for (let i = 0; i < patients.length; i++) {
    let patient = patients[i];
    if (patient.hasOwnProperty("hpo")) {
      for (var h of patient.hpo) {
        if (!hpo[h.id]) hpo[h.id] = h;
      }
    }
    if (patient.hasOwnProperty("diagnoses")) {
      for (var d of patient.diagnoses) {
        if (!diagnosis[d.id]) diagnosis[d.id] = d;
      }
    }
    if ("modeOfInheritance" in patient && patient.modeOfInheritance) {
      inherit.add(patient.modeOfInheritance);
    }
    if (!fam[patient.familyId]) {
      fam[patient.familyId] = 
        {id: patient.familyId, name: patient.familyExternalId};
    }
    indiv.push({id: patient.patientId, name: patient.individualId});
    if (patient.samples) {
      for (let j = 0; j < patient.samples.length; j++) {
        samp.push(
          {
            id: patient.samples[j].sampleId, 
            name: patient.samples[j].externalSampleId
          }
        );
      }
    }
  }
  return {
    hpo: objectToArray(hpo),
    diagnoses: objectToArray(diagnosis),
    families: objectToArray(fam),
    patients: indiv,
    samples: samp,
    inherit: Array.from(inherit)
  }
}

function patientLabs(patient) {
  if (! patient.hasOwnProperty("shareLabs")) {
    return [patient.labId];
  }
  return [patient.labId, ...patient.shareLabs];
}

// NEEDS TESTING
// DEPRECATED
function patientFilterOld(filters, patient) {
  let isNullFilter = (filter) => !filters.hasOwnProperty(filter);
  if ((isNullFilter("hpo") || filters.hpo.some(
      hpoId => patientHasItemTypeId(patient, "hpo", hpoId)))
    && (isNullFilter("projects") || filters.projects.some(
      projectId => patientHasItemTypeId(patient, "projects", projectId)))
    && (isNullFilter("labs") || filters.labs.some(
      labId => labId == patient.labId))
    && (isNullFilter("affection") || filters.affection.some(
      affect => affect == patient.affection))
  ) return true;
}

function patientFilter(patient, filterObject, functionLookup = filterFunctions) {
  const filters = [];
  for (let filterKey of Object.keys(filterObject)) {
    filters.push({
      fn: functionLookup[filterKey],
      data: filterObject[filterKey]
    });
  }
  return filters.every(filter => filter.fn(patient, filter.data));
}



// function filterHandler(filters, patient) {
//   return {
//     hpo: patientHasHpo,
//   }


function listExperiments(patient) {
  return patient.samples.reduce((exps, sample) => 
    exps.push(sample.experimentType), []);
}

export default {
  addPatientToProject: addPatientToProject
  , patientHasHpo: patientHasHpo
  , patientFilter: patientFilter
  // , patientFilter2: patientFilter2
  , gridView: gridView
  , patientLabs: patientLabs
  , removePatientFromProject: removePatientFromProject
  , searchTerms: searchTerms
};
