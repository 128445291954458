function sampleTable(samples) {
  return samples.map(s => [
    s.patient.family.name, s.patient.name, s.name, s.patient.lab.name,
    s.patient.primaryDiagnosis ? s.patient.primaryDiagnosis.name : 'N/A',
    s.patient.primaryDiagnosis ? s.patient.primaryDiagnosis.code : 'N/A',
    s.patient.affectionText, s.patient.sexText, s.createDate,
    s.experimentTypeText, s.patient.family.solvedGenesText, s.isPrimarySample,
    s.hasSnv, s.hasSv, s.hasCnn, s.hasRoh, s.hasQc,
    // QC Metrics
    s.readNumber ? String(s.readNumber) : '',
    s.inconsistentReadNumber ? String(s.inconsistentReadNumber) : '',
    s.genderMismatch ? String(s.genderMismatch) : '', 
    s.alignmentRate ? String(s.alignmentRate) : '',
    s.avgDepth ? String(s.avgDepth) : '',
    s.avgGq ? String(s.avgGq) : '',
    s.avgQual ? String(s.avgQual) : '', 
    s.duplicationRate ? String(s.duplicationRate) : '',
    s.exonEnrichment ? String(s.exonEnrichment) : '',
    s.snpCount ? String(s.snpCount) : '',
    s.indelCount ? String(s.indelCount) : '',
    s.hetHomRatio ? String(s.hetHomRatio) : '',
    s.read1Quality ? String(s.read1Quality) : '',
    s.read2Quality ? String(s.read2Quality) : '',
    s.readPairingRate ? String(s.readPairingRate) : '',
    s.readQualityDiff ? String(s.readQualityDiff) : '',
    s.referenceCoverage ? String(s.referenceCoverage) : '',
    // QC Z-Scores
    s.zAlignmentRate ? String(s.zAlignmentRate) : '',
    s.zAvgDepth ? String(s.zAvgDepth) : '',
    s.zAvgGq ? String(s.zAvgGq) : '',
    s.zAvgQual ? String(s.zAvgQual) : '',
    s.zDuplicationRate ? String(s.zDuplicationRate) : '',
    s.zExonEnrichment ? String(s.zExonEnrichment) : '',
    s.zSnpCount ? String(s.zSnpCount) : '',
    s.zIndelCount ? String(s.zIndelCount) : '',
    s.zHetHomRatio ? String(s.zHetHomRatio) : '',
    s.zRead1Quality ? String(s.zRead1Quality) : '',
    s.zRead2Quality ? String(s.zRead2Quality) : '',
    s.zReadPairingRate ? String(s.zReadPairingRate) : '',
    s.zReadQualityDiff ? String(s.zReadQualityDiff) : '',
    s.zReferenceCoverage ? String(s.zReferenceCoverage) : '',
    // GUID's
    s.patient.family.id, s.patient.id, s.id
  ]);
}

export default {sampleTable}
