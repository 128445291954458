import tgpStore from './tgpStore';
import model from './model';
import {QueryFilters}  from './queryFilters';
import SampleSet from './sampleSet';

function lookupSamplesByPatientGenomicId(patientGenomicIds) {
  return patientGenomicIds.reduce((acc, genomicId) => {
    const pat = tgpStore.findPatientByGenomicId(genomicId);
    const primarySample = pat.samples.find(s => s.isPrimarySample);
    if (primarySample) acc.push(primarySample);
    return acc;
  }, []);
}

class QueryResults {
  queryId: any;
  familyVariants: any;
  serializedParameters: any;
  sampleSet: SampleSet;
  parameters: any;
  queryFilters: InstanceType<typeof QueryFilters>;
  
  constructor(queryId, familyVariants, serializedParameters) {
    this.queryId = queryId;
    this.familyVariants = familyVariants;
    this.serializedParameters = serializedParameters;
    this.sampleSet = new SampleSet();
    const samples = serializedParameters.sampleIds.map(s => tgpStore.findSampleById(s));
    this.sampleSet.addSamples(samples)
    this.parameters = Object.assign({}, serializedParameters);
    delete this.parameters.sampleIds;
    this.queryFilters = new QueryFilters();
    this.queryFilters.applyFilterSettings(this.parameters);
  }

  exportFamilyVariants() {
    let snvAnot = this.familyVariants.filter(d => !d.isSv).map(d => [
      d.chrom, d.bp, d.ref, d.alt, d.geneText, d.hgvsc, d.hgvsp, d.enst, d.ensp,
      d.family.name,
      d.maxDiagnosis ? d.maxDiagnosis.name : null,
      d.maxDiagnosis ? d.maxDiagnosis.code : null,
      d.sampleNameText,
      d.samples.map(s => s.patient.affectionText).join(','),
      d.genotypesText,
      d.dpText, d.gqText, d.qualText, d.cnnText, d.rohText, d.refReadsText,
      d.altReadsText,
      d.maverick ? d.maverick.dom : null,
      d.maverick ? d.maverick.rec : null,
      d.maverick ? 1 - d.maverick.benign : null,
      d.maverick ? d.maverick.inherit : null,
      d.gnomad_af, d.gnomad_ac,
      d.clinvar ? d.clinvar.clinsig : null,
      d.omim ? d.omim.omimNumber : null,
      d.multiz46way, d.multiz100way, d.phastcons100way, d.phylop100way,
      d.lof != null ? model.getEnumName("lof", d.lof) : null,
      d.cadd_score,
      d.sift_pred != null ? model.getEnumName("sift_pred", d.sift_pred) : null,
      d.polyphen2_hdiv_pred != null ? model.getEnumName("polyphen2_hdiv_pred", d.polyphen2_hdiv_pred) : null,
      d.polyphen2_hvar_pred != null ? model.getEnumName("polyphen2_hvar_pred", d.polyphen2_hvar_pred) : null,
      d.lrt_pred != null ? model.getEnumName("lrt_pred", d.lrt_pred) : null,
      d.provean_pred != null ? model.getEnumName("provean_pred", d.provean_pred) : null,
      d.mutationtaster_pred != null ? model.getEnumName("mutationtaster_pred", d.mutationtaster_pred) : null,
      d.mutationassessor_pred != null ? model.getEnumName("mutationassessor_pred", d.mutationassessor_pred) : null,
      d.fathmm_pred != null ? model.getEnumName("fathmm_pred", d.fathmm_pred) : null,
      d.metasvm_pred != null ? model.getEnumName("metasvm_pred", d.metasvm_pred) : null,
      d.metalr_pred != null ? model.getEnumName("metalr_pred", d.metalr_pred) : null,
      d.vest3_score,
      d.gnomadConstraint ? d.gnomadConstraint[0].exp_lof : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].obs_lof : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].pli : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].exp_mis : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].obs_mis : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].mis_z : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].exp_syn : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].obs_syn : null,
      d.gnomadConstraint ? d.gnomadConstraint[0].syn_z : null,
    ])
    let svAnot = this.familyVariants.filter(d => d.isSv).map(d => [
      d.chrom, d.start, d.stop, d.size, d.ontologyText,
      d.isIntronic, d.isNearExon, d.isNearTss, 
      d.isOverlappingCds, d.isOverlappingExon, 
      d.encode && d.encode.enhancersOverlapped ? 
        d.encode.enhancersOverlapped.join(', ') : null, 
      d.encode && d.encode.promotersOverlapped ?
        d.encode.promotersOverlapped.join(', ') : null,
      d.encode && d.encode.ctcfSitesOverlapped ? 
        d.encode.ctcfSitesOverlapped.join(', ') : null,
      d.parliament.breakseq, d.parliament.delly, d.parliament.manta,
      d.parliament.breakdancer, d.parliament.cnvnator, d.parliament.lumpy 
    ])
    return [snvAnot, svAnot]
  }

  exportSamples() {
    function booleanToString(value) { return value ? 'Yes' : 'No'; }
    return [...this.sampleSet.samples].map(s => [
      s.patient.name, s.patient.family.name, 
      s.patient.affectionText, s.patient.sexText, s.patient.modeOfInheritance,
      s.patient.primaryDiagnosis.name, s.createDate, s.experimentTypeText,
      booleanToString(s.hasSnv), booleanToString(s.hasSv),
      booleanToString(s.hasCnn), booleanToString(s.hasRoh),
      booleanToString(s.patient.family.hasPedigree),
      s.name, s.id
    ]);
  }
  
}

export default QueryResults;
