import Papa from "papaparse";

// Return array of values serialized in a string. Use PapaParse to take
// advantage of dynamic typing (as opposed to standard split function).
function deserializeConcatString(s, delimiter = '<>') {
  s = s.replace(/NULL/g,'');  // convert nulls to empty csv values
  return Papa.parse(s, {delimiter, dynamicTyping: true}).data[0];
}

// 3 states: null means no match found, true means >10 matches,
// and a string with actual gwas data.  Only the last requires 
// handling, in order to parse the string.
function parseGwas(gwas) {
  if (!gwas || gwas == true) return gwas;
  return gwas.split('||').map(g => {
    let [gwas_risk_allele, gwas_snpid, gwas_pvalue, gwas_effect_size,
      gwas_mapped_trait, gwas_mapped_gene, gwas_pubmed_id
    ] = deserializeConcatString(g, '<>')
    return {gwas_risk_allele, gwas_snpid, gwas_pvalue, gwas_effect_size,
      gwas_mapped_trait, gwas_mapped_gene, gwas_pubmed_id};
  });
}

function parsePhenoCounts(phenodata) {
  return phenodata.split('<>').map(t => {
    let [code, pheno, hets, homs] = deserializeConcatString(t, '@');
    return {code, pheno, hets, homs};
  });
}

export default {deserializeConcatString, parseGwas, parsePhenoCounts};
