import tgpStore from './tgpStore';
import model from './model';

function findDiagnosisGroupById(diagnosisGroupId) {
  return model.diagnosisGroups.find(d => d.id == diagnosisGroupId);
}

function serializeObjectIds() {
  let result = {};
  for (let serialItem of this.serializationMap) {
    const {serialId, serialClass} = serialItem;
    if (this.hasAnyItemsOfType(serialClass))
      result[serialId] = this.filterItemsOfType(serialClass).map(s => s.id);
  }
  return result;
}

function deserializeObjectIds(serialId, serializedValues) {
  let mapItem = this.serializationMap.find(s => s.serialId == serialId);
  serializedValues.forEach(v => this.applyFilter(mapItem.lookupFn(v))); 
}

function serializeLiterals() {
  return Object.fromEntries([[this.id, this.values]]);
}

function serializeNames() {
  return Object.fromEntries([[this.id, this.values.map(v => v.name)]]);
}

function deserializeLiterals(_, serializedValues) {
  serializedValues.forEach(v => this.applyFilter(v)); 
}

function propertyInSerializationMap(propertyName) {
  return this.serializationMap.some(s => s.serialId == propertyName);
}

function propertyMatchesId(propertyName) { return this.id == propertyName; }

class SampleFilters {
  cf: any;
  filterList: (SelectedFilter | DiagnosisFilter | ExpTypeFilter | DateFilter | InheritanceFilter | MembershipFilter | SampleFilter | SolvedGeneFilter | SolvedStatusFilter)[];
  constructor(sampleCrossfilter) {
    this.cf = sampleCrossfilter.cf;
    this.filterList = [
      new DateFilter(sampleCrossfilter),
      new DiagnosisFilter(sampleCrossfilter),
      new ExpTypeFilter(sampleCrossfilter),
      new InheritanceFilter(sampleCrossfilter),
      new MembershipFilter(sampleCrossfilter),
      new SampleFilter(sampleCrossfilter),
      new SelectedFilter(sampleCrossfilter),
      new SolvedGeneFilter(sampleCrossfilter),
      new SolvedStatusFilter(sampleCrossfilter)
    ];
  }

  get activeFilters() {
    return this.filterList.filter(f => f.isActive);
  }

  get activeFilterItems() {
    return this.activeFilters.reduce((allItems, thisFilter) => {
        // @ts-ignore
        const thisFilterItems = thisFilter.activeFilterItems
          .map(item => { return {model: thisFilter, value: item}; });
        allItems.push(...thisFilterItems);
        return allItems;
      }, []);
  }

  findFilterById(filterId) {
    return this.filterList.find(f => f.id == filterId);
  }

  getFilteredSamples() { return this.cf.allFiltered(); }

  getFilteredCount() { return this.getFilteredSamples().length; }

  removeAllFilters() {
    // @ts-ignore
    this.activeFilters.forEach(f => f.removeAllFilterItems());
  }

  serialize() {
    // @ts-ignore
    return Object.assign({}, ...this.activeFilters.map(f => f.serialize()));
  }

  deserialize(settings) {
    this.removeAllFilters();
    for (let prop in settings) {
      let propFilter = this.filterList.find(
        f => f.canDeserializeProperty(prop));
      // @ts-ignore
      propFilter.deserialize(prop, settings[prop]);
    }
  }

}

class DateFilter {
  sampleCrossfilter: any;
  id: string;
  values: any;
  canDeserializeProperty: (propertyName: any) => boolean;
  refreshFn: any;
  removeAllFilterItems: () => void;
  constructor(sampleCrossfilter) {
    this.sampleCrossfilter = sampleCrossfilter;
    this.id = 'date';
    this.values = null;
    this.canDeserializeProperty = propertyMatchesId;
    this.refreshFn = sampleCrossfilter.setDateFilter.bind(sampleCrossfilter);
    this.removeAllFilterItems = this.removeFilter;
  }

  get activeFilterItems() { return [this.values]; }

  get isActive() { return this.values != null; }

  applyFilter(dateRange) {
    this.values = dateRange;
    this._refreshDimension();
  }

  removeFilter() {
    this.values = null;
    this._refreshDimension();
  }

  serialize() {
    return [this.values.startDate, this.values.endDate];
  }

  _refreshDimension() {
    this.refreshFn(this.values);
  }

}

class SelectedFilter {
  id: string;
  values: any;
  refreshFn: any;
  constructor(sampleCrossfilter) {
    this.id = 'selected';
    this.values = null;
    this.refreshFn = sampleCrossfilter.setSelectedFilter
      .bind(sampleCrossfilter);
  }

  // Filter is not serializable and does not behave like other filters, hence
  // hardcoding it as inactive avoids any handling during serialization
  get isActive() { return false; }

  applyFilter(selectedSamples) {
    this.values = selectedSamples;
    this._refreshDimension();
  }

  canDeserializeProperty() { return false;}
   
  removeFilter() {
    this.values = null;
    this._refreshDimension();
  }

  _refreshDimension() {
    this.refreshFn(this.values);
  }

}

class ArrayValuesFilter {
  sampleCrossfilter: any;
  values: any[];
  constructor(sampleCrossfilter) {
    this.sampleCrossfilter = sampleCrossfilter;
    this.values = [];
  }

  get isActive() { return this.values.length > 0; }

  get activeFilterItems() { return this.values; }

  applyFilter(newFilter) {
    this.values.push(newFilter);
    this._refreshDimension();
  }

  filterItemsOfType(type) {
    return this.values.filter(v => v.constructor.name == type);
  } 

  hasAnyItemsOfType(type) {
    return this.filterItemsOfType(type).length > 0;
  }

  removeAllFilterItems() {
    this.values = [];
    this._refreshDimension();
  }

  removeFilter(filter) {
    const idx = this.values.indexOf(filter);
    this.values.splice(idx, 1);
    this._refreshDimension();
  }

  _refreshDimension() {
    this.refreshFn(this.values);
  }
  refreshFn(values: any) {
    throw new Error('Method not implemented.');
  }

}

class DiagnosisFilter extends ArrayValuesFilter {
  id: string;
  serialize: () => {};
  deserialize: (serialId: any, serializedValues: any) => void;
  canDeserializeProperty: (propertyName: any) => any;
  serializationMap: { serialId: string; lookupFn: any; serialClass: string; }[];
  constructor(sampleCrossfilter) {
    super(sampleCrossfilter);
    this.id = 'diagnosis';
    this.sampleCrossfilter = sampleCrossfilter;
    this.serialize = serializeObjectIds;
    this.deserialize = deserializeObjectIds;
    this.canDeserializeProperty = propertyInSerializationMap;
    this.refreshFn = sampleCrossfilter.setDiagnosisFilter
      .bind(sampleCrossfilter)
    this.serializationMap = [
      {
        serialId: 'diagnoses',
        lookupFn: tgpStore.findDiagnosisById.bind(tgpStore),
        serialClass: 'Diagnosis',
      },
      {
        serialId: 'hpos',
        lookupFn: tgpStore.findHpoById.bind(tgpStore),
        serialClass: 'Hpo',
      },
      {
        serialId: 'diagnosisGroups',
        lookupFn: findDiagnosisGroupById,
        serialClass: 'DiagnosisGroup',
      },
    ];
  }

  get options() { return tgpStore.diagnosisEntities; }

}

class ExpTypeFilter extends ArrayValuesFilter {
  id: string;
  serialize: () => any;
  deserialize: (_: any, serializedValues: any) => void;
  canDeserializeProperty: (propertyName: any) => boolean;
  constructor(sampleCrossfilter) {
    super(sampleCrossfilter);
    //this.sampleCrossfilter = sampleCrossfilter;
    this.id = 'expType';
    this.serialize = serializeLiterals;
    this.deserialize = deserializeLiterals;
    this.canDeserializeProperty = propertyMatchesId;
    this.refreshFn = sampleCrossfilter.setExpTypeFilter
      .bind(sampleCrossfilter)
  }

  get options() { return tgpStore.experimentTypes; }

}

class InheritanceFilter extends ArrayValuesFilter {
  id: string;
  serialize: () => any;
  deserialize: (_: any, serializedValues: any) => void;
  canDeserializeProperty: (propertyName: any) => boolean;
  constructor(sampleCrossfilter) {
    super(sampleCrossfilter);
    this.id = 'inheritance';
    this.serialize = serializeLiterals;
    this.deserialize = deserializeLiterals;
    this.canDeserializeProperty = propertyMatchesId;
    this.refreshFn = sampleCrossfilter.setModeOfInheritanceFilter
      .bind(sampleCrossfilter)
  }

  get options() { return tgpStore.modesOfInheritance; }

}

class MembershipFilter extends ArrayValuesFilter {
  id: string;
  serialize: () => {};
  deserialize: (serialId: any, serializedValues: any) => void;
  canDeserializeProperty: (propertyName: any) => any;
  serializationMap: { serialId: string; lookupFn: any; serialClass: string; }[];
  constructor(sampleCrossfilter) {
    super(sampleCrossfilter);
    //this.sampleCrossfilter = sampleCrossfilter;
    this.id = 'membership';
    this.serialize = serializeObjectIds;
    this.deserialize = deserializeObjectIds;
    this.canDeserializeProperty = propertyInSerializationMap;
    this.refreshFn = sampleCrossfilter.setMembershipFilter
      .bind(sampleCrossfilter)
    this.serializationMap = [
      {
        serialId: 'labs',
        lookupFn: tgpStore.findLabById.bind(tgpStore),
        serialClass: 'Lab',
      },
      {
        serialId: 'projects',
        lookupFn: tgpStore.findProjectById.bind(tgpStore),
        serialClass: 'Project',
      }
    ];
  }

  get options() { return tgpStore.labsAndProjects; }

}

class SampleFilter extends ArrayValuesFilter {
  id: string;
  serialize: () => {};
  deserialize: (serialId: any, serializedValues: any) => void;
  canDeserializeProperty: (propertyName: any) => any;
  serializationMap: { serialId: string; lookupFn: any; serialClass: string; }[];
  constructor(sampleCrossfilter) {
    super(sampleCrossfilter);
    //this.sampleCrossfilter = sampleCrossfilter;
    this.id = 'samples';
    this.refreshFn = sampleCrossfilter.setSampleFilter
      .bind(sampleCrossfilter)
    this.serialize = serializeObjectIds;
    this.deserialize = deserializeObjectIds;
    this.canDeserializeProperty = propertyInSerializationMap;
    this.serializationMap = [
      {
        serialId: 'samples',
        lookupFn: tgpStore.findSampleById.bind(tgpStore),
        serialClass: 'Sample',
      },
      {
        serialId: 'patients',
        lookupFn: tgpStore.findPatientById.bind(tgpStore),
        serialClass: 'Patient',
      },
      {
        serialId: 'families',
        lookupFn: tgpStore.findFamilyById.bind(tgpStore),
        serialClass: 'Family',
      },
    ];
  }

  get options() { return tgpStore.sampleEntities; }

}

class SolvedGeneFilter extends ArrayValuesFilter {
  id: string;
  serialize: () => any;
  canDeserializeProperty: (propertyName: any) => boolean;
  constructor(sampleCrossfilter) {
    super(sampleCrossfilter);
    this.id = 'solvedGene';
    this.serialize = serializeNames;
    this.canDeserializeProperty = propertyMatchesId;
    this.refreshFn = sampleCrossfilter.setSolvedGeneFilter
      .bind(sampleCrossfilter)
  }

  get options() { 
    return tgpStore.solvedGenes;
  }

  deserialize(_, serializedValues) {
    for (let val of serializedValues)
      this.applyFilter(tgpStore.findSolvedGene(val));
  }

}

class SolvedStatusFilter extends ArrayValuesFilter {
  id: string;
  serialize: () => any;
  canDeserializeProperty: (propertyName: any) => boolean;
  constructor(sampleCrossfilter) {
    super(sampleCrossfilter);
    this.id = 'solvedStatus';
    this.serialize = serializeNames;
    this.canDeserializeProperty = propertyMatchesId;
    this.refreshFn = sampleCrossfilter.setSolvedStatusFilter
      .bind(sampleCrossfilter)
  }

  get options() {
    return tgpStore.solvedStatuses;
  }

  deserialize(_, serializedValues) {
    for (let val of serializedValues)
      this.applyFilter(tgpStore.findSolvedStatus(val));
  }

}

export default SampleFilters;
