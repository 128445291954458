import {Lab, LabMember, Project, ProjectMember} from './groups';
import Patient from './patient';

export class User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  projectMembers: ProjectMember[];
  labMembers: LabMember[];
  constructor(
    id: string,
    username: string,
    firstName: string,
    lastName: string
  ) {
    this.id = id;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.projectMembers = [];
    this.labMembers = [];
  }

  get adminLabMembers(): LabMember[] {
    return this.labMembers.filter(l => l.hasAdminAccess);
  }

  get adminLabs(): Lab[] {
    return this.adminLabMembers.map(l => l.lab);
  }

  get labs(): Lab[] {
    return this.labMembers.map(l => l.lab);
  }

  get labDashView(): any[] {
    return this.labMembers.map(lm => {
      return {
        loginUserRole: lm.role,
        isAdmin: lm.hasAdminAccess,
        edit: {name: lm.lab.name, description: lm.lab.description},
        lab: lm.lab,
      };
    });
  }

  get adminProjectMembers(): ProjectMember[] {
    return this.projectMembers.filter(p => p.isAdmin);
  }

  findLabMember(lab: Lab) {
    return this.labMembers.find(l => l.lab == lab);
  }

  findProjectMember(project: Project) {
    return this.projectMembers.find(p => p.project == project);
  }

  labRole(lab: Lab) {
    return this.findLabMember(lab).role;
  }

  labRoleGrantOptions(lab: Lab) {
    return this.findLabMember(lab).labRoleGrantOptions;
  }

  hasPatientAdminAccess(patient: Patient): boolean {
    return this.adminLabs.includes(patient.lab);
  }

  removeProjectMembership(project: Project): void {
    const i = this.projectMembers.findIndex(p => p.project == project);
    this.projectMembers.splice(i, 1);
  }

  removeLabMembership(lab: Lab): void {
    const i = this.labMembers.findIndex(p => p.lab == lab);
    this.labMembers.splice(i, 1);
  }
}
