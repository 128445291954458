import FamilyVariant from './familyVariant';
import geneRefs from '../geneRefs';
import ShareModule from './share';
const { deserializeConcatString, parseGwas, parsePhenoCounts } = ShareModule;


class FamilySv extends FamilyVariant {
  isSv: boolean;
  svid: any;
  variantId: any;
  genotypes: any;
  genotypesText: any;
  maxGeno: number;
  maxGenoText: string;
  start: any;
  stop: any;
  genes: any;
  geneText: any;
  afRating: number;
  sampleGenotypes: any[];
  family: any;
  bamletSampleGenotypes: any;
  genesisCounts: { totalSamples: any; homSamples: any; };
  ontology: any;
  parliament: { breakseq: any; breakdancer: any; delly: any; manta: any; cnvnator: any; lumpy: any; };
  parliamentText: string;
  isParliament: boolean;
  isIntronic: any;
  isNearExon: any;
  isNearTss: any;
  isOverlappingCds: any;
  isOverlappingExon: any;
  gwas: any;
  genomicOverlapsText: any[];
  phenoCounts: any;
  gnomadConstraint: any;
  omim: any;
  encode: { enhancersOverlapped: any; promotersOverlapped: any; ctcfSitesOverlapped: any; };
  gnomad: { ac: any; af: any; an: any; freqHomAlt: any; freqHet: any; freqHomRef: any; filter: any; };
  constructor(data, params) {
    super(data, params, data.sv_id || data.svid);
    this.isSv = true;
    this.svid = this.variantId;
    this.genotypes = isNaN(data.genotypes) ? 
      data.genotypes.split(",").map(i => Number(i)) : [data.genotypes];
    this.genotypesText = this.genotypes.map(g => formatSvGeno(g)).join(",");
    this.maxGeno = Math.max(...this.genotypes);
    this.maxGenoText = formatSvGeno(this.maxGeno);
    this.start = data.sv_start;
    this.stop = data.sv_stop;
    this.genes = parseSvGenes(data.vep_gene);
    this.geneText = !this.genes ? null :
      this.genes.map(g => g.name).sort().join(', ');
    this.afRating = svAfRating(this);
    let starts = isNaN(data.sv_sample_starts) ? 
      data.sv_sample_starts.split(",").map(i => Number(i)) :
      [data.sv_sample_starts];
    let stops = isNaN(data.sv_sample_ends) ? 
      data.sv_sample_ends.split(",").map(i => Number(i)) :
      [data.sv_sample_ends];
    this.sampleGenotypes =
      svSampleGenotypes(this.samples, this.genotypes, starts, stops);
    // Get all WGS samples in the family - even those w/o genotypes in this FamilySv
    const familyWgsSamples = [];
    for (let p of this.family.patients) {
      for (let s of p.samples) {
        if (s.experimentTypeText == 'WGS') familyWgsSamples.push(s);
      }
    }
    this.bamletSampleGenotypes = !familyWgsSamples.length ?
      this.sampleGenotypes :
      bamletSampleGenotypes(this.sampleGenotypes, familyWgsSamples);
    this.genesisCounts = {
      totalSamples: data.sv_sample_count || data.sv_samplecount, 
      homSamples: data.sv_homozygous_sample_count || data.sv_homozygoussamplecount
    };
    this.ontology = data.sv_type || data.svtype;
    this.parliament = {
      breakseq: data.breakseq, breakdancer: data.breakdancer, delly: data.delly,
      manta: data.manta, cnvnator: data.cnvnator, lumpy: data.lumpy
    };
    this.parliamentText = parliamentText(this.parliament);
    this.isParliament = data.breakseq || data.breakdancer || data.delly || 
      data.manta || data.cnvnator || data.lumpy ? true : false;
    this.isIntronic = data.sv_is_intronic || data.sv_isintronic;
    this.isNearExon = data.sv_is_near_exon || data.sv_isnearexon;
    this.isNearTss = data.sv_is_near_tss || data.sv_isneartss;
    this.isOverlappingCds = data.sv_overlaps_cds || data.sv_overlapscds;
    this.isOverlappingExon = data.sv_overlaps_exon || data.sv_overlapsexon;
    this.gwas = parseGwas(data.sv_gwas_gene_data);
    this.genomicOverlapsText = [];
    if (this.isOverlappingCds) this.genomicOverlapsText.push("Overlaps CDS");
    if (this.isOverlappingExon) this.genomicOverlapsText.push("Overlaps Exon");
    if (this.isNearExon) this.genomicOverlapsText.push("Near Exon");
    if (this.isNearTss) this.genomicOverlapsText.push("Near TSS");
    if (this.isIntronic) this.genomicOverlapsText.push("Intronic");
    this.phenoCounts = data.pheno_counts || data.phenocounts ?
      parsePhenoCounts(data.pheno_counts || data.phenocounts) : null;
    this.gnomadConstraint = data.sv_gnomad_constraint_data ? 
      parseSvGnomadConstraint(data.sv_gnomad_constraint_data) : null;
    this.omim = data.sv_omim_data ? parseOmim(data.sv_omim_data) : null;
    const dataSvEncodeEnhancers = data.sv_encode_enhancers_overlapped || data.sv_encodeenhancersoverlapped;
    const dataSvEncodePromoters = data.sv_encode_promoters_overlapped || data.sv_encodepromotersoverlapped;
    const dataSvEncodeCtcfSites = data.sv_encode_ctcf_sites_overlapped || data.sv_encodectcfsitesoverlapped;
    if (
      dataSvEncodeEnhancers || dataSvEncodePromoters || dataSvEncodeCtcfSites
    ) {
      this.encode = {
        enhancersOverlapped: !dataSvEncodeEnhancers ?
          null : dataSvEncodeEnhancers.split(","),
        promotersOverlapped: !dataSvEncodePromoters ?
          null : dataSvEncodePromoters.split(","),
        ctcfSitesOverlapped: !dataSvEncodeCtcfSites ?
          null : dataSvEncodeCtcfSites.split(",")
      };
    }
    this.gnomad = !this.isGnomad ? null : {
      ac: data.sv_ac, af: data.sv_af, an: data.sv_an,
      freqHomAlt: data.sv_freq_homalt,
      freqHet: data.sv_freq_het,
      freqHomRef: data.sv_freq_homref, filter: data.sv_filter
    };
  }

  get isGnomad() {
    return this.svid.startsWith("gnomAD") ? true : false;
  }

  get size() {
    return this.stop - this.start;
  }

  get ontologyText() {
    switch (this.ontology) {
      case 'DEL':
        return 'Deletion';
        break;
      case 'DUP':
        return 'Duplication';
        break;
      case 'INV':
        return 'Inversion';
        break;
      case 'INS':
        return 'Insertion';
        break;
    }
    // CPX BND
  }

}

function bamletSampleGenotypes(sampleGenotypes, wgsSamples) {
  let varSamples = sampleGenotypes.map(sg => sg.sample);
  let maxSv = sampleGenotypes.reduce((maxlen, sg) => {
    if (!maxlen.start || maxlen.stop - maxlen.start < sg.stop - sg.start) {
      return {start: sg.start, stop: sg.stop};
    } else return maxlen;
  }, {});
  let refSamples = wgsSamples.filter(s => !varSamples.includes(s));
  let refSampleGenotypes = refSamples.map(s => {
    return {
      sample: s,
      genotype: 0,
      genotypeText: formatSvGeno(0),
      start: maxSv.start,
      stop: maxSv.stop,
    };
  });
  return [...sampleGenotypes, ...refSampleGenotypes];
}

function formatSvGeno(genoCode) {
  switch (genoCode) {
    case 0:
      return "Ref/Ref";
    case 1:
      return "Ref/SV";
    case 2:
      return "SV/SV";
  }
}

function parliamentText({breakseq, breakdancer, delly, manta, cnvnator, lumpy}) {
  let a = [];
  if (breakseq) a.push('Breakseq');
  if (delly) a.push('Delly');
  if (manta) a.push('Manta');
  if (breakdancer) a.push('Breakdancer');
  if (cnvnator) a.push('CNVnator');
  if (lumpy) a.push('Lumpy');
  return a.join(', ');
}

function parseOmim(omimdata) {
  let rx1 = /(.*), (\d+) \(\d\), (.*)/, rx2 = /(.*), (\d+) \(\d\)/;
  if (omimdata == true) return omimdata;
  return omimdata.split('||').map(o => {
    let [omimNumber, geneName, gene, phenotype, phenotypes, phenoCode, 
      inheritanceCode
    ] = deserializeConcatString(o, '<>');
    phenotypes = phenotypes.split(';').map(p => {
      p = p.trim();
      if (rx1.test(p)) {
        let [phenotype, omim_phenotype_mim, inheritance] = rx1.exec(p).slice(1);
        return {phenotype, omim_phenotype_mim, inheritance};
      } else if (rx2.test(p)) {
        let [phenotype, omim_phenotype_mim] = rx2.exec(p).slice(1);
        return {phenotype, omim_phenotype_mim};
      }
    });
    return {    
      omimNumber: Number(omimNumber), phenoCode, gene,
      inheritanceCode: Number(inheritanceCode), phenotypes
    };
  });
}

// Lookup SV genes and only return entries with a HUGO match
function parseSvGenes(geneString) {
  if (!geneString) return null;  // no genes overlapped by SV
  return geneString.split(",").map(g => geneRefs.geneRefLookup(g))
    .filter(g => g && g.hgnc);
}

function parseSvGnomadConstraint(data) {
  if (data == true) return data;
  return data.split('||').map(d => {
    let [gene, pli, mis_z, obs_lof, exp_lof, obs_mis, exp_mis, 
      obs_syn, exp_syn, syn_z
    ] = deserializeConcatString(d, '<>');
    return {gene, pli, mis_z, exp_lof, obs_lof, 
      exp_mis, obs_mis, exp_syn, obs_syn, syn_z 
    };
  });
}

function svAfRating(sv) {
  if (!sv.gnomad) return 0;  
  else if (sv.gnomad.af < 0.02) return 1;
  else return 2;  // af >= 0.02
}

function svSampleGenotypes(samples, genotypes, starts, stops) {
  let a = [];
  for (let i = 0; i < genotypes.length; i++) {
    a.push({
      genotype: genotypes[i],
      genotypeText: formatSvGeno(genotypes[i]),
      sample: samples[i],
      start: starts[i],
      stop: stops[i]
    });
  }
  return a;
}

export default FamilySv;
