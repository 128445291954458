import tgpStore from './tgpStore';
import Sample from './baseEntities/sample';

class SampleSet {
  samples: Set<Sample>;

  constructor() {
    this.samples = new Set();
  }

  get completeSamples() {return Array.from(this.samples).filter(s => s.hasData); }

  get hasAnySamples() { return this.samples.size ? true : false; }

  get sampleSets() {
    const uniquePatients = new Set(
      Array.from(this.samples).map(s => s.patient))
    const uniqueFamilies = new Set(
      Array.from(this.samples).map(s => s.patient.family))
    return {
      samples: this.samples,
      patients: uniquePatients,
      families: uniqueFamilies
    };
  }

  get sampleSetsCompleted() {
    const uniquePatients = new Set(
      this.completeSamples.map(s => s.patient))
    const uniqueFamilies = new Set(
      this.completeSamples.map(s => s.patient.family))
    return {
      samples: new Set(this.completeSamples),
      patients: uniquePatients,
      families: uniqueFamilies
    };
  }

  get sampleSizes() {
    const s = this.sampleSets;
    return {
      samples: s.samples.size,
      patients: s.patients.size,
      families: s.families.size
    };
  }

  get sampleIds() {
    return Array.from(this.samples).map(s => s.id);

  }

  addSamples(samples) {
    this.samples.clear();
    samples.forEach(s => this.samples.add(s));
  }

  addSamplesById(sampleIds) {
    const samples = sampleIds.map(s => tgpStore.findSampleById(s));
    this.addSamples(samples);
  }

  equals(sampleSet) {
    return this.samples.size === sampleSet.samples.size &&
      [...this.samples].every(sample => sampleSet.samples.has(sample));
  }

}

export default SampleSet;
