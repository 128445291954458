import Papa from "papaparse";

const geneAliases = new Map();
const geneAliasesArray = [];

// Use an object with the name property to allow reuse of the primary gene
// between multiple aliases instead of storing it as a literal that can't be
// referenced.
class PrimaryGene {
  name: any;
  constructor(geneName) {
    this.name = geneName; 
  }
}

function getPrimaryGeneName(geneName) {
  return isValidGeneName(geneName) ?  geneAliases.get(geneName).name : null;
}

function init(data) {
  const primaryGeneLookup = new Map();
  const parseOpts = {header: false, skipEmptyLines: true};
  const parsed = Papa.parse(data, parseOpts);
  for (let [primaryName, alias] of parsed.data) {
    if (!primaryGeneLookup.has(primaryName)) 
      primaryGeneLookup.set(primaryName, new PrimaryGene(primaryName))
    const primaryGene = primaryGeneLookup.get(primaryName);
    geneAliases.set(alias, primaryGene);
    geneAliasesArray.push({primary: primaryName, alias});
  }
}

function isPrimaryGeneName(geneName) {
  return geneAliases.has(geneName) && geneAliases.get(geneName).name == geneName;
}

function isValidGeneName(geneName) {
  return geneAliases.has(geneName);
}

export default {
  getPrimaryGeneName,
  geneAliasesArray,
  init,
  isValidGeneName
};
