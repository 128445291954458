import crossfilter from 'crossfilter2';


class SampleCrossfilter {
  cf: any;
  dimDate: any;
  dimDiagnosis: any;
  dimExpType: any;
  dimInheritance: any;
  dimMembership: any;
  dimSample: any;
  dimSelected: any;
  dimIsSolved: any;
  dimSolvedStatus: any;
  dimSolvedGene: any;
  constructor(samples) {
    this.cf = crossfilter(samples);
    this.dimDate = this.cf.dimension(sample => sample);
    this.dimDiagnosis = this.cf.dimension(sample => sample);
    this.dimExpType = this.cf.dimension(sample => sample);
    this.dimInheritance = this.cf.dimension(sample => sample);
    this.dimMembership = this.cf.dimension(sample => sample);
    this.dimSample = this.cf.dimension(sample => sample);
    this.dimSelected = this.cf.dimension(sample => sample);
    this.dimIsSolved = this.cf.dimension(
      sample => sample.patient.family.isSolved ? 'Solved' : 'Unsolved');
    this.dimSolvedStatus = this.cf.dimension(sample => sample);
    this.dimSolvedGene = this.cf.dimension(sample => sample);
  }

  setMembershipFilter(values) {
    this.dimMembership.filterFunction(
      sample => sample.patient.hasAnyMembership(values));
  }

  setDateFilter(dateValues) {
    this.dimDate.filterFunction(sample => {
      if (!dateValues) return true;
      const {startDate, endDate} = dateValues;
      return !dateValues || 
        sample.createDate > startDate && sample.createDate <= endDate
    }); 
  }

  setDiagnosisFilter(diagnosisFilters) {
    this.dimDiagnosis.filterFunction(
      sample => sample.patient.hasAnyDiagnosis(diagnosisFilters));
  }

  setExpTypeFilter(expTypeFilters) {
    this.dimExpType.filterFunction(
      sample => sample.hasAnyExpType(expTypeFilters));
  }

  setModeOfInheritanceFilter(inheritanceFilters) {
    this.dimInheritance.filterFunction(
      sample => sample.patient.hasAnyModeOfInheritance(inheritanceFilters));
  }

  setSampleFilter(sampleFilters) {
    this.dimSample.filterFunction(
      sample => sample.hasAnySampleEntity(sampleFilters));
  }

  setSelectedFilter(selectedSamples) {
    this.dimSelected.filterFunction(
      sample => !selectedSamples || selectedSamples.has(sample));
  }

  setSolvedGeneFilter(solvedGeneFilter) {
    this.dimSolvedGene.filterFunction(sample => 
      !solvedGeneFilter.length ||
      sample.patient.family.solvedGeneNames.some(gene =>
        solvedGeneFilter.some(s => s.name == gene))
    );
  }

  setSolvedStatusFilter(solvedStatusFilter) {
    this.dimSolvedStatus.filterFunction(sample =>
      !solvedStatusFilter.length ||
      solvedStatusFilter.some(s =>
        s.id == sample.patient.family.solvedStatus)
    );
  }

}

export default SampleCrossfilter;

