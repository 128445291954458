import Papa from "papaparse";
let geneRefs = null, geneRefsIndex = {}, hugoGenes = [];

function geneRefLookup(gene) {
  return geneRefsIndex[gene];
}

function initGeneRefs(data) {
  const parseOpts = {header: true, dynamicTyping: true, skipEmptyLines: true};
  const parsed = Papa.parse(data, parseOpts);
  geneRefs = parsed.data;
  for (let g of geneRefs) {
    geneRefsIndex[g.name] = g;
  }
  const allHugoRefs = geneRefs.filter(g => g.hgnc).map(g => g.name);
  hugoGenes.push(...new Set(allHugoRefs));  // dedupe
}

function getGeneRefs() {
  return geneRefs;
}

function getUniprotId(gene) {
  const g = geneRefLookup(gene);
  return g && g.uniprot ? g.uniprot : null;
}

export default {
  initGeneRefs: initGeneRefs,
  geneRefLookup: geneRefLookup,
  getGeneRefs: getGeneRefs,
  getUniprotId: getUniprotId,
  hugoGenes: hugoGenes,
};
