class Diagnosis {
  id: string;
  code: number;
  name: string;
  source: string;
  constructor(id: string, source: string, code: number, name: string) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.source = source;
  }
}

class DiagnosisGroup {
  id: string;
  name: string;
  primaryDiagnosisCodes: number[];
  constructor(id: string, name: string, primaryDiagnosisCodes: number[]) {
    this.id = id;
    this.name = name;
    this.primaryDiagnosisCodes = primaryDiagnosisCodes;
  }
}

class Hpo {
  id: string;
  code: string;
  name: string;
  group: string;
  constructor(id: string, code: string, name: string, group: string) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.group = group;
  }
}

export {Diagnosis, DiagnosisGroup, Hpo};
