import tgpStore from './tgpStore';

function adminUserGroups(userId, memberRoleCode, groupUsers) {
  return groupUsers
    .filter(gu => gu.userId == userId && gu.role != memberRoleCode)
    .map(gu => gu.groupId);
}

function groupUserCounts(groupUsers) {
  return groupUsers.map(user => user["groupId"]).reduce(
    (acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {}
  );
}

function groupOwnerAndRole(groupUsers, loginUserId) {
  const groups = Object.create(null);
  groupUsers.forEach(function(gu) {
    if (!groups[gu.groupId]) groups[gu.groupId] = Object.create(null);
    if (gu.userId == loginUserId) {
      groups[gu.groupId]["role"] = gu.role;
    }
    if (gu.role == 0) {
      groups[gu.groupId]["owner"] = gu.lastName;
    }
  });
  return groups;
}

function labAffiliations(labs) {
  return labs.filter(l => "affiliation" in l).reduce((affiliations, l) => {
    affiliations[l.id] = l.affiliation;
    return affiliations;
  }, {});
}

function labPatientCounts(labs, patients) {
  const isValidLab = (labId) => labs.some(l => l.id == labId)
  return patients.map(patient => patient.labsWithAccess)
    .reduce((labCounts, patientLabs) => {
      for (var lab of patientLabs) {
        if (isValidLab(lab)) labCounts[lab] = (labCounts[lab] || 0) + 1;
      }
      return labCounts;
      }, {}
    );
}

function projectPatientCounts(patients) {
  return patients.reduce((projectCounts, patient) => {
      if (!patient.hasOwnProperty("projects")) return projectCounts;
      for (var project of patient.projects) {
        projectCounts[project] = (projectCounts[project] || 0) + 1;
      }
      return projectCounts;
      }, {}
    );
}

function removeGroupUsers(groupUsers, deleteGroupUsers) {
  for (let delGroupUser of deleteGroupUsers) {
    let userIdx = groupUsers.indexOf(delGroupUser);
    groupUsers.splice(userIdx, 1);
  }
}

function removeGroup(groups, groupUsers, deleteGroupId) {
  let deleteGroupIdx = groups.findIndex(g => g.id == deleteGroupId);
  groups.splice(deleteGroupIdx, 1);
  let deleteGroupUsers = groupUsers.filter(u => u.groupId == deleteGroupId);
  removeGroupUsers(groupUsers, deleteGroupUsers);
}

function setGroupUserRole(groupUserMod, groupUsers) {
  let groupUser = groupUsers.find(gu => 
    gu.groupId == groupUserMod.groupId && gu.userId == groupUserMod.userId
  );
  groupUser.role = groupUserMod.role
}

function groupTypeData(data, groupType) {
  if (groupType == "lab") return [ data.labs, data.labUsers];
  return [ data.projects, data.projectUsers];
}

function jointGroupUserData(data) {
  return [ ...data.labUsers, ...data.projectUsers];
}


function extendGroupName(groupUser, groups) {
  let idx = groups.findIndex(g => g.id == groupUser.groupId);
  let group = groups[idx];
  groupUser.groupName = group.name;
  return groupUser;
}

function newGroupMembers(groups, groupUsers, loginUserId) {
  return groupUsers
    .filter(gu => gu.userId != loginUserId)  // all users except self
    .sort((a,b) => b.membershipDate - a.membershipDate)  // most recent
    .map(gu => extendGroupName(gu, groups))
    .slice(0,5);  // return top 5
}

function newGroupMembership(groups, groupUsers, loginUserId) {
  return groupUsers
    .filter(gu => gu.userId == loginUserId)  // only self membership
    .sort((a, b) => b.membershipDate - a.membershipDate)  // most recent
    .map(gu => extendGroupName(gu, groups))
    .slice(0,5);  // return top 5
}

function groupSummary(groups, groupUsers, userId) {
  let dash = groups;
  extendObjects(dash, groupOwnerAndRole(groupUsers, userId));
  return dash;
}

function groupDash(groupType, groups, groupUsers, patients, loginUserId) {
  let dash = groupSummary(groups, groupUsers, loginUserId);
  mapValuesToObjects(dash, groupUserCounts(groupUsers), "totalUsers");
  if (groupType == "lab") {
    mapValuesToObjects(dash, labPatientCounts(groups, tgpStore.patients), "totalPatients");
    mapValuesToObjects(dash, labAffiliations(groups), "affiliation");
  } else {
    mapValuesToObjects(dash, projectPatientCounts(patients), "totalPatients");
  }
  return dash;
}

function groupMembers(groupUsers, groupId) {
  return groupUsers.filter(gu => gu["groupId"] == groupId)
}

function mapValuesToObjects(objects, keyValues, valueProp, mapProp = "id") {
  for (const [key, value] of Object.entries(keyValues)) {
    const idx = objects.findIndex(obj => obj[mapProp] == key);
    objects[idx][valueProp] = value;
  }
  return objects;
}

// Adapted from: 
// https://plainjs.com/javascript/utilities/merge-two-javascript-objects-19/
function extendObjects(objects, src, mapProp = "id") {
  Object.keys(src).forEach(function(objectKey) {
    const idx = objects.findIndex(obj => obj[mapProp] == objectKey);
    Object.keys(src[objectKey]).forEach(function(key) {
      objects[idx][key] = src[objectKey][key]
    });
  });
  return objects;
}



export default {
  adminUserGroups: adminUserGroups
  , groupUserCounts: groupUserCounts
  , groupOwnerAndRole: groupOwnerAndRole
  , jointGroupUserData: jointGroupUserData
  , labAffiliations: labAffiliations
  , removeGroup: removeGroup
  , removeGroupUsers: removeGroupUsers
  , setGroupUserRole: setGroupUserRole
  , newGroupMembers: newGroupMembers
  , newGroupMembership: newGroupMembership
  , groupMembers: groupMembers
  , groupDash: groupDash
  , groupSummary: groupSummary
  , groupTypeData: groupTypeData
};
