import model from '../model';
import Patient from './patient';

function zScore(val, exptype, fieldname) {
  if (!val || exptype == 'Targeted Panel')
    return null;
  const expStats = model.popQc.find(s => s.ExperimentType == exptype);
  const median = expStats[fieldname + '_median'];
  const mad = expStats[fieldname + '_mad'];
  return (val - median) / mad;
}

export default class Sample {
  sampleGenomicId: any;
  matchId: string;
  createDate: Date;
  hasSnv: boolean;
  hasSv: boolean;
  hasCnn: boolean;
  hasRoh: boolean;
  hasData: any;
  hasQc: boolean;
  isShared: boolean;
  name: any;
  experimentType: any;
  experimentTypeText: any;
  isPrimarySample: any;
  id: any;
  fileType: any;
  patient: Patient;
  alignmentRate: any;
  avgDepth: any;
  avgGq: any;
  avgQual: any;
  duplicationRate: any;
  exonEnrichment: any;
  genderMismatch: any;
  hetHomRatio: any;
  inconsistentReadNumber: any;
  snpCount: any;
  indelCount: any;
  read1Quality: any;
  read2Quality: any;
  readNumber: any;
  readPairingRate: any;
  readQualityDiff: any;
  referenceCoverage: any;
  dataTypesText: any[];
  zAlignmentRate: number;
  zAvgDepth: number;
  zAvgGq: number;
  zAvgQual: number;
  zDuplicationRate: number;
  zExonEnrichment: number;
  zSnpCount: number;
  zIndelCount: number;
  zHetHomRatio: number;
  zRead1Quality: number;
  zRead2Quality: number;
  zReadPairingRate: number;
  zReadQualityDiff: number;
  zReferenceCoverage: number;
  hasMemberAccess: boolean;
  constructor(
    sampleGenomicId, matchId, createDate, name, experimentType,
    isPrimarySample, hasSnv, hasSv, hasCnn, hasRoh, isShared,
    id, fileType, patient, alignmentRate, avgDepth,
    avgGq, avgQual, duplicationRate, exonEnrichment,
    genderMismatch, hetHomRatio,
    inconsistentReadNumber, snpCount, indelCount, read1Quality, read2Quality,
    readNumber, readPairingRate, readQualityDiff, referenceCoverage
  ) {
    this.sampleGenomicId = sampleGenomicId;
    this.matchId = matchId;
    this.hasMemberAccess = Boolean(name);
    this.createDate = new Date(createDate);
    this.hasSnv = hasSnv ? true : false;
    this.hasSv = hasSv ? true : false;
    this.hasCnn = hasCnn ? true : false;
    this.hasRoh = hasRoh ? true : false;
    this.hasData = this.hasSnv || this.hasSv;
    // test for FASTQ, BAM And VCF QC, respectively
    this.hasQc = readNumber || alignmentRate || snpCount ? true : false; 
    this.isShared = isShared ? true : false;
    this.name = name;
    this.experimentType = experimentType;
    this.experimentTypeText = model.getEnumName(
      'experimentType', experimentType);
    this.isPrimarySample = isPrimarySample;
    this.id = id;
    this.fileType = fileType;
    this.patient = patient;
    this.alignmentRate = alignmentRate;
    this.avgDepth = avgDepth;
    this.avgGq = avgGq;
    this.avgQual = avgQual;
    this.duplicationRate = duplicationRate;
    this.exonEnrichment = exonEnrichment;
    this.genderMismatch = genderMismatch;
    this.hetHomRatio = hetHomRatio;
    this.inconsistentReadNumber = inconsistentReadNumber;
    this.snpCount = snpCount;
    this.indelCount = indelCount;
    this.read1Quality = read1Quality;
    this.read2Quality = read2Quality;
    this.readNumber = readNumber; // No Z-score calculation
    this.readPairingRate = readPairingRate;
    this.readQualityDiff = readQualityDiff;
    this.referenceCoverage = referenceCoverage;

    this.dataTypesText = [];
    if (this.hasSnv)
      this.dataTypesText.push("SNV");
    if (this.hasSv)
      this.dataTypesText.push("SV");
    if (this.hasCnn)
      this.dataTypesText.push("CNN");
    if (this.hasRoh)
      this.dataTypesText.push("RoH");

    // Z-scores, ReadNumber not currently supported
    this.zAlignmentRate = zScore(this.alignmentRate, this.experimentTypeText, 'AlignmentRate');
    this.zAvgDepth = zScore(this.avgDepth, this.experimentTypeText, 'AvgDepth');
    this.zAvgGq = zScore(this.avgGq, this.experimentTypeText, 'AvgGQ');
    this.zAvgQual = zScore(this.avgQual, this.experimentTypeText, 'AvgQual');
    this.zDuplicationRate = zScore(this.duplicationRate, this.experimentTypeText, 'DuplicationRate');
    this.zExonEnrichment = zScore(this.exonEnrichment, this.experimentTypeText, 'ExonEnrichment');
    this.zSnpCount = zScore(this.snpCount, this.experimentTypeText, 'SNPCount');
    this.zIndelCount = zScore(this.indelCount, this.experimentTypeText, 'INDELCount');
    this.zHetHomRatio = zScore(this.hetHomRatio, this.experimentTypeText, 'HetHomRatio');
    this.zRead1Quality = zScore(this.read1Quality, this.experimentTypeText, 'Read1Quality');
    this.zRead2Quality = zScore(this.read2Quality, this.experimentTypeText, 'Read2Quality');
    this.zReadPairingRate = zScore(this.readPairingRate, this.experimentTypeText, 'ReadPairingRate');
    this.zReadQualityDiff = zScore(this.readQualityDiff, this.experimentTypeText, 'ReadQualityDiff');
    this.zReferenceCoverage = zScore(this.referenceCoverage, this.experimentTypeText, 'ReferenceCoverage');
  }

  get shareName() : string {
    return this.name || "S" + this.matchId;
  }

  get genesisNumber() : string {
    return `${this.patient.family.matchId}-P${this.patient.matchId}-S${this.matchId}`;
  } 

  hasAnyExpType(expTypeFilters) {
    return !expTypeFilters.length ||
      expTypeFilters.includes(this.experimentTypeText);
  }

  hasAnySampleEntity(sampleEntityFilters) {
    return !sampleEntityFilters.length ||
      sampleEntityFilters.includes(this) ||
      sampleEntityFilters.includes(this.patient) ||
      sampleEntityFilters.includes(this.patient.family);
  }

}
